<template>
  <div class="working-location-tab shared-index">
    <el-row class="table-title" type="flex" align="center">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
          <el-tab-pane name="working_location">
            <div slot="label">
              Nơi làm việc
              <span v-if="activeTab === 'working_location'">({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="createWorkingLocation"
        >Thêm</el-button>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          size="small"
          @click="handleChangeTab"
        >Làm mới</el-button>
      </div>
    </el-row>
    <list-working-location
      :loading="callingAPI"
      :data="allWorkingLocations"
      @reload="handleReloadAfterDelete"
      @update="updateWorkingLocation"
    />
    <el-row type="flex" justify="end" class="my-4">
      <el-pagination
        :current-page="page"
        :page-size="limit"
        :total="total"
        background
        layout="total, prev, pager, next"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <update-working-location-popup
      v-if="visibleUpdate"
      :working-location="selectedWorkingLocation"
      :visible="visibleUpdate"
      @close="visibleUpdate = false"
      @reload="loadData"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListWorkingLocation from './components/List.vue'
import UpdateWorkingLocationPopup from './components/popup/Update.vue'
import { getTranslation } from '@/utils/filters'
import { getWorkingLocations } from '@/services/working-location'

export default {
  name: 'WorkingLocation',
  components: {
    ListWorkingLocation,
    UpdateWorkingLocationPopup
  },
  data() {
    return {
      visibleUpdate: false,
      callingAPI: false,
      allWorkingLocations: [],
      selectedWorkingLocation: {},
      activeTab: 'working_location',
      total: 0,
      limit: 10,
      page: 1,
      searchText: ''
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      const p = {
        page: this.page,
        per_page: this.limit,
        locale: this.language
      }
      return p
    }
  },
  watch: {
    language: 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.callingAPI = true
      this.visibleUpdate = false
      getWorkingLocations(this.params, this.activeTab).then((response) => {
        this.allWorkingLocations = response.result.map((r) => ({
          ...r,
          name: getTranslation(r, this.language) ? getTranslation(r, this.language).name : r.name
        }))
        this.total = response.total
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },
    handleChangePage(page) {
      this.page = page
      this.loadData()
    },
    createWorkingLocation() {
      this.selectedWorkingLocation = null
      this.visibleUpdate = true
    },
    updateWorkingLocation(data) {
      this.selectedWorkingLocation = data
      this.visibleUpdate = true
    },
    handleChangeTab() {
      this.page = 1
      this.loadData()
    },
    handleReloadAfterDelete() {
      if (this.allWorkingLocations.length === 1 && this.page > 1) {
        this.page = this.page - 1
      }
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
.working-location-tab {
  .table-title {
    &__left {
      max-width: calc(100% - 200px);
      .el-tabs {
        max-width: 100%;
      }
    }
  }
}
</style>
