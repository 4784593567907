<template>
  <el-dialog
    top="80px"
    width="600px"
    class="package-form"
    :title="!workingLocation ? 'Thêm mới nơi làm việc' : 'Chỉnh sửa nơi làm việc'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item label="Tên nơi làm việc" prop="name">
        <el-input v-model="form.name" placeholder="Nhập vào tên nơi làm việc"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdate">{{ !workingLocation ? 'Tạo' : 'Cập nhật' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createWorkingLocation, updateWorkingLocation } from '@/services/working-location'
export default {
  name: 'UpdateWorkingLocation',
  props: {
    workingLocation: Object,
    visible: Boolean
  },
  data() {
    return {
      callingAPI: false,
      form: {
        name: this.workingLocation ? this.workingLocation.name : ''
      },
      rules: {
        name: [
          { required: true, message: 'Tên nơi làm việc không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    dataInput() {
      const data = {
        name: this.form.name,
        locale: this.language
      }
      if (this.workingLocation) {
        data.id = this.workingLocation.id
      }
      return data
    }
  },
  methods: {
    handleUpdate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.workingLocation) {
            createWorkingLocation(this.dataInput, this.type).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Tạo mới nơi làm việc thành công'
              })
              this.$emit('reload')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          } else {
            updateWorkingLocation(this.dataInput, this.type).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Cập nhật nơi làm việc thành công'
              })
              this.$emit('reload')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          }
        }
      })
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
